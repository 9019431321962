import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import React from 'react'
import MiniDrawer from "../../components/Sidebar";
import loginstyles from "../login/login.styles";
import clientstyles from "../clientFeedback/clientfeedback.styles";
// import { Delete, Edit } from '@mui/icons-material';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { info } from "console";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../../global/urlConstant";
import { useNavigate } from "react-router-dom";

const BackgroundCard = styled(Card)({
  backgroundColor: "#C98630",
  borderRadius: "12px",
  overflow: "hidden",
  width: 250, // Adjust width as needed
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  textAlign: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
});

const ImageContainer = styled(Box)({
  position: "relative",
  width: "100%",
  height: 180, // Adjust height as needed
  overflow: "hidden",
});

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const classes = clientstyles;

interface ClientData {
  id: any;
  address: string;
  contact: number;
  emailAddress: any;
  gymName: string;
  subscription: any;
  uniqueId: number;
  userId: number;
  website: any;
  createdAt: number;
  updatedAt: number;
  expiresAt: number;
  isExpiredSoon: boolean;
  plan: string;
  amount: number;
  isExpired: boolean;
  status: boolean;
}

const data: ClientData[] = Array(10).fill({
  name: "Priyanshu",
  joiningDate: "00-00-00",
  contact: "00000 000000",
  email: "priya@gmail.com",
  expiryDate: "00-00-00",
  status: "Active",
  plan: "Plan",
  isEnabled: true,
});

const createData = (
  id: any,
  address: string,
  contact: number,
  emailAddress: any,
  gymName: string,
  subscription: any,
  uniqueId: number,
  userId: number,
  website: any,
  createdAt: number,
  updatedAt: number,
  expiresAt: number,
  isExpiredSoon: boolean,
  plan: string,
  amount: number,
  isExpired: boolean,
  status: boolean
) => {
  return {
    id,
    address,
    contact,
    emailAddress,
    gymName,
    subscription,
    uniqueId,
    userId,
    website,
    createdAt,
    updatedAt,
    expiresAt,
    isExpiredSoon,
    plan,
    amount,
    isExpired,
    status,
  };
};

// const rows = [
//   createData(
//     1,
//     "Himanshu",
//     "12/10/2003",
//     9658472536,
//     "abc@info.com",
//     "13/03/2010",
//     "ACTIVE",
//     "PLAN",
//     true
//   ),
//   createData(
//     2,
//     "Himanshua",
//     "12/10/2003",
//     9658472536,
//     "abc@info.com",
//     "13/03/2010",
//     "ACTIVE",
//     "PLAN",
//     true
//   ),
//   createData(
//     3,
//     "Himanshu",
//     "12/10/2003",
//     9658472536,
//     "abc@info.com",
//     "13/03/2010",
//     "ACTIVE",
//     "PLAN",
//     true
//   ),
// ];

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [dashboardData, setdashboardData] = useState<any>([]);
  const [gymCount, setGymCount] = useState([]);
  const [clientFeedbackCount, setClientFeedbackCount] = useState([]);
  const [currDate, setCurrDate] = useState<number>();

  const [selectedRow, setSelectedRow] = useState<any>();

  const handleEnableDateChange = (e: any) => {
    let tempDate = formatDateToYYYYMMDD(e.target.value);

    setCurrDate(tempDate);
  };

  console.log("Dashboard data", dashboardData);

  useEffect(() => {
    isUserLoggedIn();
    // getGymOwnerCount();
    getClientFeedbackCount();
    getAllGym();
  }, []);

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  const getGymOwnerCount = async () => {
    const response = await axios.get(
      `${urls.baseUrl}/api/dynamic/count/GymOwner`
    );
    setGymCount(response.data.count);
  };

  const getClientFeedbackCount = async () => {
    const response = await axios.get(`${urls.baseUrl}/api/feedbacks`);
    setClientFeedbackCount(response.data.total);
  };

  function goToDashboardUpdate(row: any) {
    navigate("/dashboardUpdate", { state: row });
  }

  function goToGymPage() {
    navigate("/gymdetail");
  }

  function goToClientPage() {
    navigate("/clientFeedback");
  }

  function goToClientfeedback() {
    navigate("/dashboard");
  }
  function goToaddClients() {
    navigate("/addclient");
  }

  const onfilterChange = (event: any) => {
    let input = event.target.value;
    if (input) {
      let filteredData = dashboardData?.filter(
        (o: any) =>
          o.gymName.toLowerCase().includes(input.toLowerCase()) ||
          o.emailAddress.toLowerCase().includes(input.toLowerCase()) ||
          o.contact.toLowerCase().includes(input.toLowerCase())
      );
      setdashboardData(filteredData);
    } else {
      getAllGym();
    }

    console.log("input", input);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = (row: any) => {
    console.log("testing", row);

    setSelectedRow(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getFirst15Characters = (str: String) => {
    const subName = str?.length > 16 ? str?.slice(0, 16) : str;
    if (str.length > 16) {
      return subName + "...";
    } else {
      return str;
    }
  };

  const formatDateToYYYYMMDD = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return Number(`${year}${month}${day}`); // Convert to number in YYYYMMDD format
  };

  function formatDateManually1(isoDateString: string | number | Date) {
    console.log("isoDateString ", isoDateString);
    if (isoDateString) {
      // Convert the input string "20251030" into a Date object
      const isoDateStringFormatted = isoDateString.toString();
      const year = isoDateStringFormatted.slice(0, 4); // Extract year
      const month = isoDateStringFormatted.slice(4, 6); // Extract month
      const day = isoDateStringFormatted.slice(6, 8); // Extract day

      // Return the formatted string as "yyyy/mm/dd"
      return `${year} /${month}/${day}`;
    } else {
      return null;
    }
  }
  function formatDateManually(isoDateString: string | number | Date) {
    console.log("isoDateString ", isoDateString);
    if (isoDateString) {
      // Convert the input string "20251030" into a Date object
      const isoDateStringFormatted = isoDateString.toString();
      const year = isoDateStringFormatted.slice(0, 4); // Extract year
      const month = isoDateStringFormatted.slice(4, 6); // Extract month
      const day = isoDateStringFormatted.slice(6, 8); // Extract day

      // Return the formatted string as "yyyy/mm/dd"
      return `${year} /${month}/${day}`;
    } else {
      return null;
    }
  }
  // for selection in the table

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // const handleSelectAll = (event: any) => {
  //   const isChecked = event.target.checked;
  //   setSelectAll(isChecked);

  //   // Update all rows to be selected/deselected
  //   if (isChecked) {
  //     setSelectedRows(rows.map((row) => row.id)); // Assuming rows have a unique 'id'
  //   } else {
  //     setSelectedRows([]);
  //   }
  // };

  const handleConfirmDelete = () => {
    // console.log("Current Row is : ", selectedRow);
    axios
      .delete(`${urls.baseUrl}/api/auth/signup`)
      .then(function (response) {
        console.log(response);
        toast.success("Successfully Deleted!", {
          position: "top-right",
        });
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Delete failed. Please try again.", {
          position: "top-right",
        });
      });
  };

  const handleRowSelect = (id: number) => {
    setSelectedRows(
      (prev) =>
        prev.includes(id)
          ? prev.filter((rowId) => rowId !== id) // Deselect if already selected
          : [...prev, id] // Select if not selected
    );
  };

  const getAllGym = async () => {
    await axios
      .get(`${urls.baseUrl}/api/gyms?isExpired=true`)
      .then(function (response) {
        console.log(response);
        const filterData = response.data.data.filter(
          (item: any) => item?.isExpired === true
        );
        // for(){

        // }
        setdashboardData(filterData);
        setGymCount(response.data.total);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Technical issue while getting gym details!", {
          position: "top-right",
        });
      });
  };

  // const filterExpiredData = ()=>{

  // }

  const handleExport = async () => {
    try {
      // Make API request to get the file
      const response = await axios.post(
        `${urls.baseUrl}/api/gyms/export/csv?isExpired=true`
        // {
        //   responseType: "blob", // Ensure the response is treated as a binary file
        // }
      );

      // Create a blob and download the file
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_file.csv"; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        paddingLeft: "20px",
        paddingTop: "16px",
      }}
    >
      <MiniDrawer />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* <Typography sx={classes.mainHead}>
            Sales Report
          </Typography> */}
          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>

        <div className={styles.container}>
          <h2 className={styles.greeting}>Hello Priyanshu</h2>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Stack sx={{ marginLeft: "-18px" }}>
                <Grid
                  container
                  rowSpacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid xs={12} sm={12} md={6} lg={6}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      sx={{ flexBasis: "1% !important" }}
                    >
                      {" "}
                      {/* Reduced column widths */}
                      <BackgroundCard
                        sx={{
                          transform: "scale(0.85)",
                          borderRadius: "0",
                        }}
                      >
                        {" "}
                        {/* Shrinks the card by 15% */}
                        <ImageContainer
                          sx={{
                            height: "120px !important",
                            padding: "3px",
                          }}
                        >
                          {" "}
                          {/* Reduced padding */}
                          <Image
                            src="images\dashboard\Rectangle 39.png"
                            alt="Gym"
                            style={{
                              width: "100%",
                              padding: "5px",
                              borderRadius: "10px",
                            }} // Shrinks image width and padding
                          />
                          <Overlay>
                            <Box
                              sx={{
                                width: "40px", // Smaller width
                                height: "30px", // Smaller height
                                display: "flex",

                                fontSize: "16px", // Smaller font size
                              }}
                            >
                              <img
                                src="images\dashboard\gym-near 1 (1).png"
                                alt=""
                                style={{ width: "100%", height: "40px" }}
                              />
                            </Box>
                            <Typography
                              variant="subtitle1" // Use a smaller text variant
                              style={{ fontWeight: "bold", marginTop: "20px" }} // Smaller margin
                            >
                              {gymCount}
                            </Typography>
                          </Overlay>
                        </ImageContainer>
                        <CardContent>
                          <Typography
                            variant="subtitle2" // Smaller variant for text
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={goToGymPage}
                          >
                            Expired Gym
                          </Typography>
                        </CardContent>
                      </BackgroundCard>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} lg={6}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                      sx={{ flexBasis: "1% !important" }}
                    >
                      {" "}
                      {/* Reduced column widths */}
                      <BackgroundCard
                        sx={{ transform: "scale(0.85)", borderRadius: "0" }}
                      >
                        {" "}
                        {/* Shrinks the card by 15% */}
                        <ImageContainer
                          sx={{
                            // flexBasis: "1% !important",
                            height: "120px !important",
                            padding: "3px",
                          }}
                        >
                          {" "}
                          {/* Reduced padding */}
                          <Image
                            src="images/dashboard/Rectangle 42.png"
                            alt="Gym"
                            style={{
                              width: "100%",
                              padding: "5px",
                              borderRadius: "10px",
                            }} // Shrinks image width and padding
                          />
                          <Overlay>
                            <Box
                              sx={{
                                width: "40px", // Smaller width
                                height: "30px", // Smaller height
                                display: "flex",

                                fontSize: "16px", // Smaller font size
                              }}
                            >
                              <img
                                src="images/dashboard/customer (1) 1.png"
                                alt=""
                                style={{ width: "100%", height: "40px" }}
                              />
                            </Box>
                            <Typography
                              variant="subtitle1" // Use a smaller text variant
                              style={{ fontWeight: "bold", marginTop: "20px" }} // Smaller margin
                            >
                              {clientFeedbackCount}
                            </Typography>
                          </Overlay>
                        </ImageContainer>
                        <CardContent>
                          <Typography
                            variant="subtitle2" // Smaller variant for text
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={goToClientPage}
                          >
                            Client Feedback
                          </Typography>
                        </CardContent>
                      </BackgroundCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Box>

          <div className={styles.expiredSection}>
            <h3 className={styles.subTitle}>Expired Client</h3>
            {/* <a href="#view-all" className={styles.viewAll}>View All</a> */}
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginRight: 1,
            marginLeft: "-10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "",
            }}
          >
            <Typography sx={{ marginLeft: "5px", padding: "10px" }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#C98630" }}
                onClick={handleExport}
              >
                Export
              </Button>
              {/* <Button
                size="small"
                style={{
                  marginLeft: "10px",
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Clear Filter
              </Button> */}
            </Typography>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "10px", textAlign: "right" }}
            ></Grid>
            {/* </Grid> */}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter"
              size="small"
              sx={{ maxWidth: 160, margin: " 5px 0 5px 16px" }}
              onCanPlay={onfilterChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />{" "}
            {/* <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#C98630",
                maxWidth: 160,
                margin: " 10px 0 10px 16px",
              }}
              onClick={goToaddClients}
            >
              Add Clients
            </Button> */}
          </Box>
        </Box>

        <TableContainer sx={classes.tableContainer}>
          <Table sx={{ marginLeft: "-8px" }}>
            <TableHead>
              <TableRow>
                {/* <TableCell >Name of gym</TableCell>
                <TableCell >Unique Id</TableCell>
                <TableCell >Plan</TableCell>
                <TableCell >Price</TableCell>
                <TableCell >Total</TableCell>
                <TableCell ></TableCell>
                <TableCell ></TableCell>
                <TableCell ></TableCell>
                <TableCell style={{ textDecoration: 'underline' }}></TableCell>
                <TableCell >View/Edit </TableCell>
                <TableCell >Delete</TableCell> */}
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}

                <TableCell style={{ fontWeight: "bold", minWidth: 220 }}>
                  Gym Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 220 }}>
                  Email Address
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Contact
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 220 }}>
                  Address
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Subscription
                </TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Unique ID
                </TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  User ID
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Website
                </TableCell>

                <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Joined At
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Updated At
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Expires At
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  isExpired Soon
                </TableCell> */}

                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Plan
                </TableCell>
                <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Amount
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 160 }}>
                  Is Expired
                </TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold", minWidth: 120 }}>
                  Status
                </TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold" }}>View/Edit </TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell> */}
                {/* <TableCell style={{ fontWeight: "bold" }}>Enable</TableCell> */}

                {/* <TableCell style={{textAlign:'center'}}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardData.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    height: "36px", // Adjust height
                    "& .MuiTableCell-root": {
                      // Adjust padding for all TableCells in this row
                    },
                    "&:hover": {
                      backgroundColor: "#FFDEDE",
                      cursor: "pointer",
                    },
                  }}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleRowSelect(row.id)}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Tooltip title={`${row.gymName}`} placement="bottom">
                      <Typography>
                        {getFirst15Characters(row.gymName)}
                      </Typography>
                    </Tooltip>
                  </TableCell>

                  <TableCell>{row.emailAddress}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  {/* <TableCell>{row.subscription}</TableCell> */}
                  {/* <TableCell>{row.uniqueId}</TableCell> */}
                  {/* <TableCell>{row.userId}</TableCell> */}
                  <TableCell>{row.website}</TableCell>

                  <TableCell>{
                    formatDateManually(formatDateToYYYYMMDD(row.createdAt))
                  }</TableCell>
                  {/* <TableCell>{formatDateManually(row.updatedAt)}</TableCell> */}
                  <TableCell>{formatDateManually(row.expiresAt)}</TableCell>
                  {/* <TableCell>{formatDateManually(row.isExpiredSoon)}</TableCell> */}
                  <TableCell>{row.plan}</TableCell>
                  <TableCell>{row.amount}</TableCell>

                  {/* <TableCell>{formatDateManually(row.isExpired)}</TableCell> */}

                  {/* <TableCell>{row.status}</TableCell> */}

                  {/* <TableCell>
                    <Button
                      onClick={() => goToDashboardUpdate(row)}
                      sx={{ zIndex: "1" }}
                    >
                      <EditIcon style={{ color: "rgb(201, 134, 48)" }} />
                    </Button>
                  </TableCell> */}
                  {/* <TableCell>
                    <Button onClick={handleOpen}>
                      <DeleteOutlineIcon
                        style={{ color: "rgb(201, 134, 48)" }}
                      />
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      sx={{ backgroundColor: "black !important" }}
                    >
                      <Box sx={style}>
                        <Box
                          component="main"
                          sx={{
                            padding: "0px !important",
                            flexGrow: 1,
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack>
                            <Grid
                              container
                              style={{
                                backgroundColor: "#C98630",
                                borderRadius: "10px",
                              }}
                            >
                              <Grid item>
                                <Typography
                                  style={{
                                    backgroundColor: " #FFDCAE",
                                    textAlign: "center",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Dashboard Name
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                  }}
                                >
                                  <Typography style={{ color: "white" }}>
                                    Are you sure to delete this?
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleConfirmDelete}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleClose}
                                  >
                                    No
                                  </Button>
                                   </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Box>
                      </Box>
                    </Modal>
                  </TableCell> */}

                  <TableCell>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: row.isExpired ? " #00FF80" : "RED",
                        color: row.isExpired ? "black" : "white",
                        borderRadius: "5px",
                      }}
                      size="small"
                      onClick={() => handleOpen(row)}
                      fullWidth
                    >
                      {row.isExpired ? "Enable" : "Disable"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* enable modal code starts below */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ backgroundColor: "black !important" }}
      >
        <Box sx={style}>
          <Box
            component="main"
            sx={{
              padding: "0px !important",
              flexGrow: 1,
              p: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack>
              <Grid
                container
                style={{
                  backgroundColor: "#C98630",
                  borderRadius: "10px",
                }}
              >
                <Grid item>
                  <Typography
                    style={{
                      backgroundColor: " #FFDCAE",
                      textAlign: "center",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    Dashboard Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px",
                      margin: "5px",
                    }}
                  >
                    {/* <ErrorOutlineRoundedIcon style={{color:'white',margin:'0 5px'}}/> */}
                    <Typography style={{ color: "white" }}>
                      Gym Name: {selectedRow?.gymName}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      Date: {selectedRow?.expiresAt}
                    </Typography>
                    <Typography style={{ color: "white" }}>
                      Select Date:{" "}
                      <input
                        type="date"
                        name=""
                        id=""
                        onChange={handleEnableDateChange}
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "5px",
                      margin: "5px",
                      justifyContent: "space-around",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFDCAE",
                        padding: "7px 22px",
                        borderRadius: "10px",
                        margin: "2px 24px",
                        color: "black",
                      }}
                      // onClick={handleConfirmDelete}
                      // disabled={currDate}

                      onClick={async () => {
                        await axios.put(
                          `${urls.baseUrl}/api/gyms/${selectedRow.uniqueId}`,
                          {
                            expiresAt: currDate,
                            isExpired: selectedRow.isExpired ? false : true,
                          }
                        );
                        handleClose();
                        getAllGym();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#FFDCAE",
                        padding: "7px 22px",
                        borderRadius: "10px",
                        margin: "2px 24px",
                        color: "black",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    {/* <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>Yes</Typography>
                       <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>No</Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
      </Modal>
      {/* enable modal code ends here */}

    </Box>
  );
};

export default Dashboard;
function useSelect(): { selectedOption: any; setSelectedOption: any } {
  throw new Error("Function not implemented.");
}
