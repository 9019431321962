import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./screens/login/Login";
import Sidebar from "./components/Sidebar";
import Dashboard from "./screens/dashboard/Dashboard";
import GymDetail from "./screens/gymDetail/GymDetail";
import GymDetailUpdate from "./screens/gymDetail/GymDetailUpdate";
import Clientfeedback from "./screens/clientFeedback/Clientfeedback";
import Clientfeedbackupdate from "./screens/clientFeedback/Clientfeedbackupdate";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MiniDrawer from "./components/Sidebar";
import ClientQuery from "./screens/clientQuery/ClientQuery";

import SalesReport from "./screens/salesReport/SalesReport";
import ClientQueryUpdate from "./screens/clientQuery/ClientQueryUpdate";
import SalesReportUpdate from "./screens/salesReport/SalesReportUpdate";
import DashboardUpdate from "./screens/dashboard/DashboardUpdate";
import GymDetailOneUpdate from "./screens/gymDetail/GymDetailOneUpdate";
import GymDetailOneUpdateEdit from "./screens/gymDetail/GymDetailOneUpdateEdit";
import ImageUplaod from "./components/ImageUplaod";
import SignUp from "./components/signUp/SignUp";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import AddGym from "./screens/gymDetail/AddGym";
import AddMember from "./screens/gymDetail/AddMember";
import AddSalesReport from "./screens/salesReport/AddSalesReport";
import AddQuery from "./screens/clientQuery/AddQuery";
import AddFeedback from "./screens/clientFeedback/AddFeedback";
import AddClient from "./screens/dashboard/AddClient";
import SetNewPassword from "./components/forgotPassword/SetNewPassword";

function App() {
  return (
    <>
      {/* <SignUp /> */}

      <BrowserRouter>
        {/* <MiniDrawer /> */}
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboardUpdate" element={<DashboardUpdate />} />
          <Route path="/addclient" element={<AddClient />} />

          <Route path="/gymDetail" element={<GymDetail />} />
          <Route path="/gymDetailUpdate" element={<GymDetailUpdate />} />
          <Route path="/gymDetailOneUpdate" element={<GymDetailOneUpdate />} />
          <Route path="/addgym" element={<AddGym />} />
          <Route path="/addmember" element={<AddMember />} />
          <Route
            path="/gymDetailOneUpdateEdit"
            element={<GymDetailOneUpdateEdit />}
          />

          <Route path="/clientfeedback" element={<Clientfeedback />} />
          <Route
            path="/clientFeedbackUpdate"
            element={<Clientfeedbackupdate />}
          />
          <Route path="/addfeedback" element={<AddFeedback />} />

          <Route path="/clientQuery" element={<ClientQuery />} />
          <Route path="/clientQueryUpdate" element={<ClientQueryUpdate />} />
          <Route path="/ImageUpload" element={<ImageUplaod />} />
          <Route path="/addquery" element={<AddQuery />} />

          <Route path="/salesReport" element={<SalesReport />} />
          <Route path="/salesReportUpdate" element={<SalesReportUpdate />} />
          <Route path="/addsalesreport" element={<AddSalesReport />} />
          <Route path="/set-password/:token" element={<SetNewPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
