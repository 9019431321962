import React from 'react'

const ImageUplaod = () => {
  return (
    <div>
      hryy
    </div>
  )
}

export default ImageUplaod;
