// SalesReport.tsx
import React, { useEffect, useState } from "react";
import styles from "./SalesReport.module.css";
// import Select from '@material-ui/core/Select';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import React from 'react'
import MiniDrawer from "../../components/Sidebar";
import loginstyles from "../login/login.styles";
import clientstyles from "../clientFeedback/clientfeedback.styles";
// import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import { toast } from "react-toastify";
import { LineChart } from "@mui/x-charts/LineChart/LineChart";
import urls from "../../global/urlConstant";

// import clientQuerystyles from './clientQuery.styles';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const createData = (
  id: number,
  name: string,
  uniqueId: number,
  plan: any,
  startDate: string,
  endDate: string,
  price: number,
  total: number
) => {
  return { id, name, uniqueId, plan, startDate, endDate, price, total };
};

const rows = [
  createData(
    1,
    "priyansh ",
    45656456,
    "PLAN",
    "12/02/2024",
    "13/03/2024",
    345,
    543
  ),
  createData(
    2,
    "Priyansh",
    45656456,
    "PLAN",
    "12/02/2024",
    "13/03/2024",
    345,
    543
  ),
  createData(
    3,
    "Rahul",
    45656456,
    "PLAN",
    "12/02/2024",
    "13/03/2024",
    345,
    543
  ),
  createData(4, "Monu", 45656456, "PLAN", "12/02/2024", "13/03/2024", 345, 543),
  createData(
    5,
    "Himanshu",
    45656456,
    "PLAN",
    "12/02/2024",
    "13/03/2024",
    345,
    543
  ),
];

const classes = clientstyles;

const SalesReport: React.FC = () => {
  const [salesData, setsalesData] = useState([]);
  const navigate = useNavigate();
  console.log(salesData);

  useEffect(() => {
    isUserLoggedIn();
    getallSales();
  }, []);

  const isUserLoggedIn = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      localStorage.removeItem("authToken");
      navigate("/");
    }
  };

  function goToSalesReportUpdate(row: any) {
    navigate("/salesReportUpdate", { state: row });
  }

  function goToClientfeedback() {
    navigate("/dashboard");
  }
  function goToaddSalesReport() {
    navigate("/addsalesreport");
  }

  const onfilterChange = (event: any) => {
    let input = event.target.value;
    if (input) {
      let filteredData = salesData?.filter(
        (o: any) =>
          o.name.toLowerCase().includes(input.toLowerCase()) ||
          o.memberId.toLowerCase().includes(input.toLowerCase())
      );
      setsalesData(filteredData);
    } else {
      getallSales();
    }

    console.log("input", input);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const getFirst15Characters = (str: String) => {
  //   const subName = str.length > 10 ? str.slice(0, 10) : str;
  //   return subName + "...";
  // };

  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  // for selection in the table

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleConfirmDelete = () => {
    // console.log("Current Row is : ", selectedRow);
    axios
      .delete(`${urls.baseUrl}/api/auth/signup`)
      .then(function (response) {
        console.log(response);
        toast.success("Successfully Deleted!", {
          position: "top-right",
        });
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Delete failed. Please try again.", {
          position: "top-right",
        });
      });
  };

  const handleSelectAll = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    // Update all rows to be selected/deselected
    if (isChecked) {
      setSelectedRows(rows.map((row) => row.id)); // Assuming rows have a unique 'id'
    } else {
      setSelectedRows([]);
    }
  };

  function formatDateManually(isoDateString: string | number | Date) {
    const date = new Date(isoDateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const handleRowSelect = (id: number) => {
    setSelectedRows(
      (prev) =>
        prev.includes(id)
          ? prev.filter((rowId) => rowId !== id) // Deselect if already selected
          : [...prev, id] // Select if not selected
    );
  };

  const getallSales = async () => {
    await axios
      .get(`${urls.baseUrl}/api/gyms`)
      .then(function (response) {
        console.log(response);
        setsalesData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Technical issue while getting gym details!", {
          position: "top-right",
        });
      });
  };

  // pagination code starts here

  const [page, setPage] = useState(1); // Current page (1-based index)
  const rowsPerPage = 10; // Rows per page (fixed to 10)

  const totalPages = Math.ceil(salesData.length / rowsPerPage); // Total pages

  // Get current rows to display based on the current page
  const currentRows = salesData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage); // Update the current page
    }
  };

  // pagination code ends here

  const handleExport = async () => {
    try {
      // Make API request to get the file
      const response = await axios.post(
        `${urls.baseUrl}/api/gyms/export/csv`
        // {
        //   responseType: "blob", // Ensure the response is treated as a binary file
        // }
      );

      // Create a blob and download the file
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_file.csv"; // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        paddingLeft: "10px",
      }}
    >
      <MiniDrawer />
      <Box
        sx={{ display: "flex", flexDirection: "column", paddingTop: "16px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={classes.mainHead}>Sales Report</Typography>
          {/* <Box style={{
            marginRight: '10px',
            display: 'flex',
          }}>
            <div className={styles.formGroup} >

              <select className={styles.selectField} style={{ padding: '7px 6px', margin: '0 5px', backgroundColor: 'rgb(201, 134, 48)', border: 'none', borderRadius: '5px' }}>
                <option value="">One Year</option>
                <option value="plan1">2022</option>
                <option value="plan2">2023</option>
                <option value="plan2">2024</option>
              </select>
            </div>
            <div className={styles.formGroup}>

              <select className={styles.selectField} style={{ padding: '7px 6px', border: '1px solid rgb(201, 134, 48)', borderRadius: '5px' }}>
                <option value="">Filter</option>

              </select>
            </div>
          </Box> */}

          {/* <img src="images/dashboard/sort 1.svg" alt="" /> */}
          {/* <Select value="One Year"></Select> */}
        </Box>
        {/* <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#C98630",
            margin: "0px 5px 0px 18px",
            maxWidth: "160px",
          }}
          onClick={goToaddSalesReport}
        >
          Add Sales Report
        </Button> */}

        <Box>
          {" "}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
              width={300}
              height={200}
            />
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginRight: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginLeft: "5px", padding: "10px" }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: "#C98630" }}
                onClick={handleExport}
              >
                Export
              </Button>
              {/* <Button
                size="small"
                style={{
                  marginLeft: "10px",
                  color: "black",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                Clear Filter
              </Button> */}
            </Typography>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              style={{ padding: "10px", textAlign: "right" }}
            ></Grid>
            {/* </Grid> */}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter"
              size="small"
              sx={{ maxWidth: 160, margin: " 5px 0 5px 16px" }}
              onChange={onfilterChange}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />{" "}
            {/* <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#C98630",
                maxWidth: 160,
                margin: " 10px 0 10px 16px",
              }}
              onClick={goToaddSalesReport}
            >
              Add Sales Report
            </Button> */}
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
          }}
        >
          {/* <p style={{ cursor: 'pointer' ,textAlign:'right',margin:'15px'}}>
          <Button type="submit" variant="contained" >Add</Button>
         </p> */}
        </Box>
        <TableContainer sx={classes.tableContainer}>
          <Table sx={{}}>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectAll}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    minWidth: 140,
                  }}
                >
                  Name of gym
                  {/* <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Gym 1</MenuItem>
                    <MenuItem value={20}>Gym 2</MenuItem>
                    <MenuItem value={30}>Gym 3</MenuItem>
                  </Select> */}
                  {/* <FormControl
                    variant="standard"
                    sx={{
                      minWidth: 120,
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px dotted black", // Default bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px dotted black", // Focused bottom border
                      },
                    }}
                  >
                   
                  </FormControl> */}
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    minWidth: 140,
                  }}
                >
                  User Id
                  {/* <TextField
                    variant="standard" // Use "standard" variant for an underline style
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px dotted black", // Default bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px dotted black", // Focused bottom border
                      },
                    }}
                  /> */}
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Package
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    minWidth: 100,
                  }}
                >
                  Start Date
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    minWidth: 100,
                  }}
                >
                  End Date
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    minWidth: 100,
                  }}
                >
                  Amount
                </TableCell>

                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Price
                 
                </TableCell> */}

                {/* <TableCell
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Total
                 
                </TableCell> */}

                <TableCell style={{ textDecoration: "underline" }}></TableCell>
                {/* <TableCell></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>View/Edit </TableCell>
                <TableCell></TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>

                {/* <TableCell style={{textAlign:'center'}}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {salesData.map((row: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    height: "36px", // Adjust height
                    "& .MuiTableCell-root": {
                      // Adjust padding for all TableCells in this row
                    },
                    "&:hover": {
                      backgroundColor: "#FFDEDE",
                      cursor: "pointer",
                    },
                  }}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleRowSelect(row.id)}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Tooltip title={`${row.gymName}`} placement="bottom">
                      <Button style={{ color: "black" }}>
                        {/* {getFirst15Characters(row.name)} */}
                        {row.gymName}
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>{row.userId}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{row.subscription}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{formatDateManually(row.createdAt)}</TableCell>

                  <TableCell></TableCell>
                  <TableCell>{formatDateManually(row.updatedAt)}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{row.amount}</TableCell>

                  {/* <TableCell>{row.price}</TableCell> */}
                  <TableCell></TableCell>
                  {/* <TableCell>{row.total}</TableCell> */}
                  {/* <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell> */}
                  {/* <TableCell>
                    <Button
                      onClick={() => {
                        goToSalesReportUpdate(row);
                      }}
                      sx={{ zIndex: "1" }}
                    >
                      <EditIcon style={{ color: "rgb(201, 134, 48)" }} />
                    </Button>
                  </TableCell>
                  <TableCell></TableCell> */}
                  <TableCell>
                    <Button onClick={handleOpen}>
                      <DeleteOutlineIcon
                        style={{ color: "rgb(201, 134, 48)" }}
                      />
                    </Button>

                    {/* delete functionality starts here */}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box
                          component="main"
                          sx={{
                            padding: "0px !important",
                            flexGrow: 1,
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack>
                            <Grid
                              container
                              style={{
                                backgroundColor: "#C98630",
                                borderRadius: "10px",
                              }}
                            >
                              <Grid item>
                                <Typography
                                  style={{
                                    backgroundColor: " #FFDCAE",
                                    textAlign: "center",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Dashboard Name
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                  }}
                                >
                                  {/* <ErrorOutlineRoundedIcon style={{color:'white',margin:'0 5px'}}/> */}
                                  <Typography style={{ color: "white" }}>
                                    Are you sure to delete this?
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "5px",
                                    margin: "5px",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleConfirmDelete}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: "#FFDCAE",
                                      padding: "7px 22px",
                                      borderRadius: "10px",
                                      margin: "2px 24px",
                                      color: "black",
                                    }}
                                    onClick={handleClose}
                                  >
                                    No
                                  </Button>
                                  {/* <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>Yes</Typography>
                       <Typography style={{backgroundColor:'#FFDCAE',padding:'7px 22px',borderRadius:'10px', margin:'2px 24px'}}>No</Typography> */}
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Box>
                      </Box>
                    </Modal>
                    {/* delete functionality ends here */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Pagination
            count={40}
            variant="outlined"
            shape="rounded"
            sx={{ color: "red" }}
          /> */}

          {/* pagination code  starts here*/}

          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              // style={{ marginRight: "8px" }}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              &lt; Previous
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                sx={{
                  border: "1px solid rgb(201, 134, 48) !important",
                  color: "black !important",
                  backgroundColor: "white !important",
                  "&:hover": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:visited": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:focus": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                  "&:active": {
                    backgroundColor: "rgb(201, 134, 48) !important",
                  },
                }}
                key={index + 1}
                variant={index + 1 === page ? "contained" : "outlined"}
                size="small"
                onClick={() => handlePageChange(index + 1)}
                style={{ marginRight: "4px" }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              variant="outlined"
              size="small"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages}
              sx={{
                border: "1px solid rgb(201, 134, 48) !important",
                color: "black !important",
                marginRight: "8px",
              }}
            >
              Next &gt;
            </Button>
          </Box>

          {/* pagination code  ends here*/}
        </Stack>
      </Box>
    </Box>
  );
};

export default SalesReport;
