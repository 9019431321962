import Dashboard from "./screens/dashboard/Dashboard";
import GymDetail from "./screens/gymDetail/GymDetail";
import Clientfeedback from "./screens/clientFeedback/Clientfeedback";
import DashboardCustomizeSharpIcon from "@mui/icons-material/DashboardCustomizeSharp";
import FitnessCenterTwoToneIcon from "@mui/icons-material/FitnessCenterTwoTone";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Login from "./screens/login/Login";

import ClientQuery from "./screens/clientQuery/ClientQuery";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import SalesReport from "./screens/salesReport/SalesReport";
import { Icon } from "@iconify-icon/react";

export default function () {
  const data = [
    // {

    //     id: 0,
    //     label: "Dashboard",
    //     icon: <DashboardCustomizeSharpIcon />,
    //     component: <Dashboard />,
    //     path: "/dashboard",

    // },

    {
      id: 0,
      label: "Dashboard",
      icon: (
        <Icon
          icon="solar:key-minimalistic-square-linear"
          style={{ fontSize: "25px" }}
        />
      ),
      component: <Dashboard />,
      path: "/dashboard",
    },

    {
      id: 1,
      label: "Gym Detail",
      icon: <Icon icon="iconoir:gym" style={{ fontSize: "25px" }} />,
      component: <GymDetail />,
      path: "/gymdetail",
    },
    {
      id: 2,
      label: "Client Feedback",
      icon: (
        <Icon
          icon="fluent:person-feedback-28-regular"
          style={{ fontSize: "25px" }}
        />
      ),
      component: <Clientfeedback />,
      path: "/clientFeedback",
    },
    {
      id: 3,
      label: "Client Query",
      icon: <Icon icon="carbon:query" style={{ fontSize: "25px" }} />,
      component: <ClientQuery />,
      path: "/clientQuery",
    },
    {

        id: 4,
        label: "Sales Report",
        icon: <Icon icon="carbon:report" style={{fontSize:'25px'}}/>,
        component: <SalesReport />,
        path: '/salesReport'

    },
    {
      id: 5,
      label: "Logout",
      icon: (
        <Icon icon="material-symbols:logout" style={{ fontSize: "25px" }} />
      ),
      component: <Clientfeedback />,
      path: "/",
    },
  ];

  return data;
}

// export default data;
