import React, { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import loginstyles from "../../screens/login/login.styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import urls from "../../global/urlConstant";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const classes = loginstyles;

const SignUp = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [number, setNumber] = useState();

  const handleSubmit = (event: any) => {
    const obj = {
      name: name,
      email: email,
      password: password,
      mobileNumber: number,
    };
    console.log("obj", obj);
    axios
      .post(`${urls.baseUrl}/api/auth/signup`, obj)
      .then(function (response) {
        console.log(response);
        toast.success("Signup successful!", {
          position: "top-right",
        });
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Signup failed. Please try again.", {
          position: "top-right",
        });
      });
  };

  const handleNameChange = (event: any) => {
    console.log(event.target.value);
    setName(event.target.value);
  };
  const handleEmailChange = (event: any) => {
    console.log(event.target.value);
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: any) => {
    console.log(event.target.value);
    setPassword(event.target.value);
  };
  const handleNumberChange = (event: any) => {
    console.log(event.target.value);
    setNumber(event.target.value);
  };

  return (
    <Box fontStyle={classes.mainStack}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: " 0" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={classes.formClass}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              fontSize="40px"
              fontWeight="bolder"
              sx={{ textAlign: "center" }}
            >
              Sign Up
            </Typography>

            <Box sx={classes.formLabel}>
              <label style={{ fontSize: "15px" }}>Name</label>
              <br />
              <TextField
                placeholder="Your Name"
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={handleNameChange}
              />
            </Box>
            <Box sx={classes.formLabel}>
              <label style={{ fontSize: "15px" }}>Email Id</label>
              <br />
              <TextField
                placeholder="Your Email"
                type="email"
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={handleEmailChange}
              />
            </Box>
            <Box sx={classes.formLabel}>
              <label style={{ fontSize: "15px" }}>Password</label>
              <br />
              <TextField
                placeholder="Your Password"
                size="small"
                type="password"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={handlePasswordChange}
              />
            </Box>
            <Box sx={classes.formLabel}>
              <label style={{ fontSize: "15px" }}>Mobile No.</label>
              <br />
              <TextField
                placeholder="Your No."
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.35)",
                  borderRadius: "10px",
                  marginTop: "5px",
                }}
                onChange={handleNumberChange}
              />
              <br />
            </Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: " #00FF80",
                color: "black",
                borderRadius: "10px",
              }}
              size="small"
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
};

export default SignUp;
