import { Height, Padding } from "@mui/icons-material";
import { colors, FormLabel } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const loginstyles = {
  body: {
    overFlowX: "hidden",
  },

  mainStack: {
    background: 'url("images/login/background-img.png")',
    backgroundSize: "cover",
    fontFamily: "Poppins",
  },

  formClass: {
    background: "#C98630",
    color: "white",
    margin: "2vh",
    padding: "10vh",
    borderRadius: "15px",
    // width: '358px',
    // height: '418px',
  },

  formLabel: {
    color: "white",
    fontSize: "12px",
    padding: "10px",
    marginBottom: "0px",
  },

  loginImage: {
    width: "100%",
    height: "auto",
    "@media (max-width: 910px)": {
      display: "none",
      width: "0",
      Height: "0",
    },
  },
};
export default loginstyles;
